import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from   "../../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../../images/support-icon.svg";
import ShieldIconImage from "../../../images/shield-icon.svg";
import CustomizeIconImage from "../../../images/customize-icon.svg";
import FastIconImage from "../../../images/fast-icon.svg";
import ReliableIconImage from "../../../images/reliable-icon.svg";
import SimpleIconImage from "../../../images/simple-icon.svg";
import MockTest from "../../../images/exam.png"
import CompExam from "../../../images/analysis.png"
import Foundation from "../../../images/online-learning.png"
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    // {
    //   imageSrc: ShieldIconImage,
    //   title: "Ads Management",
    //   description: "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    // },
    { imageSrc: Foundation, title: "Foundation Courses", description:"Foundation classes at Sansaptak provide a solid academic bedrock for students, offering comprehensive learning to establish a strong understanding of core subjects. These classes lay the groundwork for future academic success, ensuring students are well-equipped with fundamental knowledge and skills." },
    { imageSrc: MockTest, title: "Mock Tests", description:"Mock tests at Sansaptak serve as invaluable tools for students, offering a simulated exam environment that enhances preparation and builds confidence. These practice tests are strategically designed to assess and improve comprehension, providing students with a realistic preview of exam conditions to optimize their performance." },
    { imageSrc: CompExam, title: "Preperation Of Competitive Exams" , description:"Sansaptak's competitive exam preparations are meticulously crafted to propel students towards success in exams like WBJEE, JEE, NEET, and various talent search competitions. Our specialized coaching combines in-depth subject knowledge with strategic exam techniques, ensuring a comprehensive approach to mastering the complexities of these competitive exams. "},
    // { imageSrc: FastIconImage, title: "PR Campaign" },
    // { imageSrc: SimpleIconImage, title: "Product Expansion" }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our <span tw="text-primary-500">Programs</span></Heading>
        {/* <DecoratorBlob /> */}
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
