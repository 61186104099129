import TwoColumnWithInput from 'components/hero/TwoColumnWithInput'
import React,{useRef} from 'react'
import { css } from "styled-components/macro";
import tw from "twin.macro";
import Header from "../../components/headers/light";
import Hero from 'sansaptak/components/hero/Hero';
import Feature from 'sansaptak/components/features/Feature';
import Services from 'sansaptak/components/features/Services';
import WhyUs from 'sansaptak/components/features/WhyUs';
import Mission from 'sansaptak/components/mission/Mission';
import Student from 'sansaptak/components/students/Student';
import Footer from 'sansaptak/components/footer/Footer';
import Navbar from 'sansaptak/components/navbar/Navbar';
export default function LandingPage() {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  console.log(windowSize)
  const PrimaryDiv = tw.div`p-4`;

  return (
    <PrimaryDiv data-tw='text-red-800'>
       {/* {
        windowSize.current[1]>700 ?
        <div style={{width:"60px", height:"60px",backgroundColor:"red", position:"fixed",borderRadius:"50%", right:"120px",bottom:"100px", zIndex:"10"}}> </div> : null
       } */}

        <Navbar/>
        <Hero/>
        <Services/>
        <Feature/>
        <Mission/>
        <WhyUs/>
        <Student/>
        <Footer/>
    </PrimaryDiv>
  )
}
