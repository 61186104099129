import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../../images/SANSAPTAK LOGO.png";
import { ReactComponent as FacebookIcon } from "../../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-4 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const ContactInfo = tw.div`text-gray-200 mt-5 px-5 w-[400px] text-center`
const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container id="contact">
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Sansaptak</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="#about">About</Link>
            <Link href="#mission">Mission & Vision</Link>
            <Link href="#whyus">Why US</Link>
            <Link href="#students">Students</Link>
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <ContactInfo>Email : <span>thetrustedname.sansaptak@gmail.com</span></ContactInfo>
          <ContactInfo>Phone : <span>9231092313</span></ContactInfo>
          <ContactInfo>Address :  88/4 Lal Bahadur Shastri Road, near Konnagar Railway Station & Anandam Primary School , Konnagar, Hooghly - 712235, West Bengal</ContactInfo>
          <CopyrightText>
            &copy; Copyright 2024, Sansaptak. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
